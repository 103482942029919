import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Link } from "gatsby"
import styles from "./styles.module.scss"
import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Меню работает на тебя",
		description: (
			<>
				<p>
					На кассовом терминале простая навигация и подсказки для официантов. А
					для руководителя — возможность планировать продажи.
				</p>
				<p>
					Программа для автоматизации кафе позволяет контролировать актуальность меню. Сделай на терминале папку
					с новинками или высокомаржинальными блюдами. Персонал в начале смены проверит план и будет предлагать
					гостям то, что выгодно продать. С автоматизацией ты сможешь снизить потери продуктов и увеличить
					эффективность работы.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/cafe-feature-1.png"
				alt="кассовый терминал для кафе"
				breakpoints={[380, 668, 840, 484]}
				sizes="(max-width: 500px) 380px, (max-width: 800px) 668px, (max-width: 1319px) 840, (min-width: 1320px) 484px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
	{
		title: "Всё, что нужно для привлечения гостей в кафе",
		mobileTitle: "Всё, что нужно для привлечения гостей в кафе",
		description: (
			<div className={styles.smallDescription}>
				<p>
					Quick Resto поддержит акции, бонусы, абонементы, купоны,
					спецпредложения и другие программы лояльности. Мы{" "}
					<Link to={pagesLinks.indexWL.href}>
						бесплатно разработаем мобильное приложение
					</Link>{" "}
					для кафе, и ты сможешь предложить гостям доставку и предзаказ с онлайн-оплатой.
				</p>
				<p>
					Волнуешься, что официанты будут недобросовестно применять программы лояльности?
					Облачная POS система для кафе&nbsp;&mdash; это возможность удалённо контролировать процессы.
				</p>
				<p>
					Используй системный подход: аналитика покажет, какие акции и&nbsp;бонусные программы кафе
					действительно работают и&nbsp;приносят прибыль.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/cafe-feature-2.png"
				alt="программы лояльности в кафе"
				breakpoints={[430, 824, 682]}
				sizes="(max-width: 500px) 430px, (max-width: 1319px) 824px, (min-width: 1320px) 682px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Кассовая программа с ускоренным приёмом заказов",
		description: (
			<>
				<p>
					Используй портативные кассовые терминалы&nbsp;&mdash; планшеты, с&nbsp;которыми официанты будут подходить
					к&nbsp;столикам. Система автоматизации работы кафе ускорит приём заказа, ведь официанту не&nbsp;нужно
					будет дополнительно вносить блюда на&nbsp;терминале. Статистика показывает: когда официант демонстрирует
					гостям фото блюд на&nbsp;своём терминале, повышаются продажи нужных позиций. Действующие акции также
					отображаются на&nbsp;экране кассы.
				</p>
				<p>
					Доверить кассу персоналу — безопасно. В программе можно ограничить права доступа и
					настроить оповещения о подозрительных операциях. Quick Resto позволяет контролировать
					оборот наличных в общепите: нельзя закрыть и открыть смену, пока не введёшь сумму
					в денежном ящике. Ошибок с типом оплаты не будет: терминал запросит подтверждение.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/cafe-feature-3.png"
				alt="кассовая программа для кафе"
				objectFit="cover"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,

		className: styles.feature3,
		theme: "white",
	},
	{
		title: "Автоматические отчёты и аналитика продаж",
		mobileTitle: "Автоматические отчёты и аналитика продаж",
		description: (
			<>
				<p>
					Автоматизация кафе — это простой сбор отчётов для аналитики. Ты сможешь оценить эффективность
					бонусной программы, работу сотрудников, прибыльность блюд и многое другое.
				</p>
				<p>
					Используй{" "}
					<Link to={pagesLinks.indexQRM.href}>
						мобильное приложение Quick Resto Manager
					</Link>{" "}
					— решение для быстрого удалённого доступа к статистике заведения. Приложение
					покажет сумму в кассе, средний чек, количество гостей за смену и
					остатки на складе. Данные можно просмотреть как по одному предприятию общепита, так и по всей сети сразу.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image4}
				src="./assets/cafe-feature-4.png"
				alt="аналитика продаж в кафе"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature4,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.reports.href,
	},
	{
		title: "Официант больше не бегает на кухню",
		description: (
			<>
				<p>
					У поваров на кухне есть свой терминал —{" "}
					<Link to={pagesLinks.indexKDS.href}>Kitchen Display System</Link> для обратной связи с официантом.
					Повар оставит комментарий к&nbsp;заказу, если что-то пошло не&nbsp;так, и&nbsp;отметит готовность блюда.
					Официанты не&nbsp;забудут о&nbsp;пожеланиях гостей: в&nbsp;программе учёта можно зафиксировать замену
					ингредиента и&nbsp;порядок подачи.
				</p>
				<p>
					Система автоматизации кафе ускоряет обслуживание и экономит деньги: для передачи заказов больше
					не нужны принтер и термобумага. С программой автоматизации проще контролировать повара.
					Отчёт покажет, сколько времени он потратил на приготовление блюда.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image5}
				src="./assets/cafe-feature-5.png"
				alt="кухонный экран повара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature5,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexKDS.href,
		backdrop: <div className={styles.feature5AdditionalBg} />,
	},
	{
		title: "Заманчиво простая работа со складом",
		description: (
			<p>
				Принимай товар прямо на&nbsp;кассовом терминале. Система управления кафе позволит делегировать эту задачу
				кассиру. Это безопасно: остатки на&nbsp;складе меняются только после проведения документа,
				а&nbsp;отметку о&nbsp;проведении руководитель может проставить удалённо. Ошибок в&nbsp;учёте продуктов
				станет меньше, а&nbsp;ещё система подскажет, если какой-то из&nbsp;ингредиентов заканчивается.
				<br/>
				<br/>
				С&nbsp;системой автоматизации кафе в&nbsp;складском учёте полный порядок: предусмотрены документы для списания,
				перемещения, разделки. Можно настроить интеграцию с&nbsp;дополнительными сервисами для бизнеса.
				В&nbsp;программе есть решения для передачи данных в&nbsp;ЕГАИС, &laquo;Меркурий&raquo; и&nbsp;&laquo;Честный Знак&raquo;.
				Обмен документами и&nbsp;онлайн-выгрузка в&nbsp;1C&nbsp;облегчат работу бухгалтера.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image6}
				src="./assets/cafe-feature-6.png"
				alt="складской учет в кафе"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature6,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.warehouse.href,
	},
]
