import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Автоматизация кафе&nbsp;</span>
	),
	price: "от 1990 ₽/мес",
	subTitle:
		"Раскрой потенциал бизнеса с облачной системой автоматизации кафе Quick Resto. В одной программе все решения: сценарии работы с блюдами и продуктами, приём заказов на кассе, расчёт гостей и выдача чека, контроль персонала, складской учёт и аналитика продаж.",
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/kafe-presentation.png"
			alt="автоматизация кафе"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
